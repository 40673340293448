import * as React from "react";
import "../style/card.css";
import { Avatar, Box, Button, ButtonGroup, Flex, Text } from "@chakra-ui/react";
import { faHeart, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Card extends React.Component {
  render() {
    return (
      <Box my="4">
        <Flex>
          <Avatar
            mr="4"
            src="https://cdn.discordapp.com/avatars/121919449996460033/59524db9e8bc131ba4065c20b0bcefba.png?size=256"
          />
          <Box>
            <Flex alignItems="center">
              <Text as="b" fontSize="lg" mr="2">
                Veld
              </Text>
              <Text as="span" fontSize="md" color="gray.600">
                @veld · 23m
              </Text>
            </Flex>
            <Text py="1">
              Some long ass tweet about anime bullshit! Haha this is so cool.
              Hopefully this is epic enough to be sweet.
            </Text>
            <ButtonGroup>
              <Button size="sm" leftIcon={<FontAwesomeIcon icon={faHeart} />}>
                {Math.round(Math.random() * 100)}
              </Button>
              <Button size="sm" leftIcon={<FontAwesomeIcon icon={faShare} />}>
                {Math.round(Math.random() * 10)}
              </Button>
            </ButtonGroup>
          </Box>
        </Flex>
      </Box>
    );
  }
}
