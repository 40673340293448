import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { PostMessage } from "../components/post-message";
import { Link } from "gatsby";
import { Divider, SimpleGrid } from "@chakra-ui/layout";
import { Box, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";
import Card from "../components/card";

const IndexPage = () => {
  const color = useColorModeValue("gray.100", "gray.700");
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });

  return (
    <Layout>
      <SEO title="Home" />
      <SimpleGrid
        width="100%"
        columns={[1, null, 2]}
        templateColumns={isDesktop ? "1fr 320px" : "1fr"}
        columnGap="4"
      >
        <Box width="full">
          <PostMessage />
          <Card />
          <Divider my="2" />
          <Card />
          <Divider my="2" />
          <Card />
          <Divider my="2" />
          <Card />
          <Divider my="2" />
          <Card />
          <Divider my="2" />
          <Card />
          <Divider my="2" />
          <Card />
          <Divider my="2" />
          <Card />
          <Divider my="2" />
          <Card />
        </Box>
        {isDesktop && (
          <Box borderRadius="md" maxWidth="420px">
            <h2>Trending Now</h2>
            <Box bgColor={color} padding="4">
              <Link to="/hash/twitter-is-down-lol">Twitter is down lol</Link>
            </Box>
          </Box>
        )}
      </SimpleGrid>
    </Layout>
  );
};

export default IndexPage;
