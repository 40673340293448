import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Textarea,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useUser } from "../hooks/useUser";
import { Link } from "gatsby";

export const PostMessage = () => {
  function submitMessage() {}
  const user = useUser();
  const color = useColorModeValue("gray.100", "gray.700");

  if (!user) {
    return (
      <Box bg={color} borderRadius="md" p="4">
        <Flex alignItems="center" justifyContent="space-between">
          <Text>Join the conversation.</Text>
          <Link to="/register">
            <Button>Register</Button>
          </Link>
        </Flex>
      </Box>
    );
  }

  return (
    <Box borderRadius="md" bg={color} padding="2">
      <Flex>
        <Box mr="2">
          <Avatar src={user?.avatar} />
        </Box>
        <div style={{ flex: 1 }}>
          <Textarea
            placeholder="What's on your mind?"
            onSubmit={submitMessage}
          />
          <Flex justifyContent="space-between">
            <div />
            <Button bgColor="blue.400">Post</Button>
          </Flex>
        </div>
      </Flex>
    </Box>
  );
};
